import $ from 'jquery';
window.$ = $;
import bootstrap from './vendor/bootstrap';
import dropdownHover from './vendor/dropdownHover';
import jQBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';
import Flickity from 'flickity';

$.bridget('isotope', Isotope);
$.bridget('flickity', Flickity);

$(document).ready(function() {
	
	$.fn.bootstrapDropdownHover({ clickBehavior: 'disable' });
	
	$('.hamburger').click(function() {
		if ($('.mobile-filter-menu').is(':visible')) {
			$('.filter-button-mobile').trigger('click');
		}
		if ($('.mobile-nav').is(':visible')) {
			$('.top-bar').removeClass('solid');
			$('.mobile-nav-overlay').fadeOut(200);
			$('.mobile-nav').slideUp(200);
			unlockScrolling();
		} else {
			$('.top-bar').addClass('solid');
			$('.mobile-nav-overlay').fadeIn(200);
			$('.mobile-nav').slideDown(200);
			lockScrolling();
		}
	});
	
	$('.mobile-nav-overlay').click(function() {
		$('.hamburger').trigger('click');
	});
	
	$('.mobile-nav a').click(function() {
		$('.hamburger').trigger('click');
	});
	
	if ($('.grid').length > 0) {
		
		let $grid = $('.grid');

		$(window).resize(function() {
			$grid.find('img').each(function() {
				var ratio = $(this).attr('width') / $(this).attr('height');
				var new_height = $(this).width() / ratio;
				$(this).height(new_height);
			});
			
			if ($(window).width() < 1024) {
				$('.mobile-filter-menu').height($(window).height() - $('.top-bar').height());
			}
		});

		$(window).trigger('resize');
		
		setTimeout(function() {
			$grid.isotope({
				itemSelector: '.grid-item',
				percentPosition: true,
				masonry: {
					columnWidth: '.grid-sizer'
				}
			});
		}, 50);
		
		$('.filter-link').click(function(e) {
			e.preventDefault();
			$(this).closest('.filters').find('li').removeClass('active');
			$(this).parent().addClass('active');
			
			let filters = [];
			$('.filters').find('li.active').each(function() {
				let filter = $(this).find('a').data('filter');
				if (filter != '') {
					filters.push(filter);
				}
			});
						
			$grid.isotope({ filter: filters.join('') });
			
			if (filters.length > 0) {
				if ($('.clear-filters').length == 0) {
					$('.left-nav').append('<button type="button" class="btn btn-default clear-filters"><i class="fa fa-times"></i></button>');
				}
			} else {
				if ($('.clear-filters').length > 0) {
					$('.clear-filters').remove();	
				}
			}
			
			return false;
		});
		
		$('.left-nav').on('click', '.clear-filters', function() {
			$grid.isotope({ filter: '' });
			$('.filters').find('li').removeClass('active');
			$('.filters').each(function() {
				$(this).find('li').eq(0).addClass('active');
			});
			$('.clear-filters').remove();
		});
		
		$('.filter-button-mobile').click(function() {
			if ($('.mobile-nav').is(':visible')) {
				$('.hamburger').trigger('click');
			}
			if ($('.mobile-filter-menu').is(':visible')) {
				$('.top-bar').removeClass('solid');
				$('.mobile-filter-menu').slideUp(200);
				unlockScrolling();
			} else {
				$('.top-bar').addClass('solid');
				$('.mobile-filter-menu').slideDown(200);
				lockScrolling();
			}
		});
		
		$('.mobile-filter-link').click(function(e) {
			e.preventDefault();
			$(this).closest('.mobile-filters').find('li').removeClass('active');
			$(this).parent().addClass('active');
			return false;
		});
		
		$('.mobile-save-filter-button').click(function() {
			let filters = [];
			$('.mobile-filters').find('li.active').each(function() {
				let filter = $(this).find('a').data('filter');
				if (filter != '') {
					filters.push(filter);
				}
			});
						
			$grid.isotope({ filter: filters.join('') });
			$('.filter-button-mobile').trigger('click');
		});
		
		$('.mobile-reset-filter-button').click(function() {
			$grid.isotope({ filter: '' });
			$('.mobile-filters').find('li').removeClass('active');
			$('.mobile-filters').each(function() {
				$(this).find('li').eq(0).addClass('active');
			});
			$('.filter-button-mobile').trigger('click');
		});
	}
	
	if ($('.carousel').length > 0) {
		
		let $carousel = $('.carousel');
		
		$(window).resize(function() {
			$carousel.find('img').each(function() {
				var ratio = $(this).attr('height') / $(this).attr('width');
				var new_width = $(this).height() / ratio;
				$(this).width(new_width);
			});
		});
		
		$(window).trigger('resize');
		
		setTimeout(function() {
			$carousel.flickity({
				cellAlign: 'left',
				contain: true
			});
		}, 50);
		
		// EBAY
		let title = $('.title > h1').text();
		let keywords = $('.ebay-container').data('keywords');
		if (keywords == '') {
			keywords = title;
		}
		$.get('/ebay.php?keywords=' + encodeURI(keywords) + '%20Scarf', function(response) {
			if (response.length > 2) {
				generateEbayTable(JSON.parse(response));
				$('.ebay-container').find('h2').html(`Want one? We found these <span class="item-name">${title}s</span> on eBay for you:`);
				$('.ebay-container').slideDown();
			} else {
				keywords = 'Lululemon Vinyasa Scarf';
				$.get('/ebay.php?keywords=' + encodeURI(keywords), function(response) {
					$('.ebay-container').find('h2').html(`We couldn't find any <span class="item-name">${title}s</span>, but thought you might like these instead:`);
					generateEbayTable(JSON.parse(response));
					$('.ebay-container').slideDown();
				});
			}
		});
		
	}
	
});

function generateEbayTable(items) {

		$.each(items, function() {
			let timeleft = this.timeleft.replace('P', '').replace('T','');
			timeleft = timeleft.split('D');
			let days = timeleft[0];
			timeleft = timeleft[1].split('H');
			let hours = timeleft[0];
			timeleft = timeleft[1].split('M');
			let minutes = timeleft[0];
			timeleft = timeleft[1].split('S');
			let seconds = timeleft[0];

			if (days > 0) {
				if (days == 1) {
					days = '1 day, ';
				} else {
					days = days + ' days, ';
				}
			} else {
				days = '';
			}
			if (hours > 0) {
				if (hours == 1) {
					hours = '1 hours, ';
				} else {
					hours = hours + ' hours, ';
				}
			} else {
				hours = '';
			}
			if (minutes > 0) {
				if (minutes == 1) {
					minutes = '1 day, ';
				} else {
					minutes = minutes + ' minutes';
				}
			} else {
				minutes = '';
			}

			let link = `http://rover.ebay.com/rover/1/711-53200-19255-0/1?icep_ff3=2&pub=5575163529&toolid=10001&campid=5337846952&customid=&icep_item=${this.id}&ipn=psmain&icep_vectorid=229466&kwid=902099&mtid=824&kw=lg`;

			$('.ebay-items').append(`
				<tr onclick="window.open('${link}');">
					<td><img src="${this.pic}" /></td>
					<td>
						<span class="title">${this.title}<br>
						<span class="timeleft">${days}${hours}${minutes} left</span>
					</td>
					<td>
						<span class="price">$${parseInt(this.price).toFixed(2)}</span><br>
					</td>
				</tr>
			`);
		});
}

function lockScrolling() {
	var scrollPosition = [
		self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
		self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
	];
	var html = $('body');
	html.data('scroll-position', scrollPosition);
	html.css('position', 'fixed');
	html.css('top', -scrollPosition[1]);
}

function unlockScrolling() {
	var html = $('body');
	var scrollPosition = html.data('scroll-position');
	html.css('position', 'relative');
	html.css('top', 'auto');
	window.scrollTo(scrollPosition[0], scrollPosition[1]);
}